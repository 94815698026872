@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 300;
  src:url("../fonts/NanumGothic-Regular.ttf") format("ttf"),
 
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src:url("../fonts/NanumGothic-ExtraBold.ttf") format("ttf"),
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 500;
  src:url("../fonts/NanumGothic-Bold.ttf") format("ttf"),
 
}


html,
body {
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-family: 'Nanum Gothic','segoe-ui-black-regular','Spoqa Han Sans', 'Spoqa Han Sans JP', "Nunito", "sans-serif";

}

main {
  z-index: 100;
  width:100%;
}
main > *:nth-child(odd) {
  background-color: #ffffff;
}

.contact_title_section {
 width:100%;
 height:400px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.contact_title_section_title{
    margin-top: 200px;
    font-size: 80px;
    margin-bottom: 24px;
    font-weight: 700;
}
.contact_title_section_descryption{

    margin-bottom: 0px;
    font-size: 21px;
    font-weight: 400;
    letter-spacing: -0.66px;
    line-height: 38px;
    color: rgba(25, 25, 27, 0.75);
}
.contact_info_section{
    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 40px;
    box-sizing: border-box;
    min-width: 0px;
    border-radius: 10px;
    background-color: rgb(247, 247, 251);
    padding: 80px ;
}
.contact_info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact_title{
    font-weight: 700;
    letter-spacing: -0.66px;
    line-height: 28px;
    color: rgb(25, 25, 27);
    font-size: 24px;
}
.contact_text{
    margin-bottom: 0px;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 38px;
    color: rgba(25, 25, 27, 0.75);
    margin-top: 12px;
    
}
.or_message_container{

    padding-top: 3rem!important;
    margin-top: 1.5rem!important;
    
    
    display: flex;
    align-items: center;
    justify-content: center;

}
.or_message_text{

    font-weight: 700;
    letter-spacing: -0.66px;
    line-height: 28px;
    color: rgb(25, 25, 27);
    font-size: 24px;
    text-align: center;
}
.send_message_container{
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 12px;
    

}
.message_content_container{

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin:16px;
}
.message_content_title{
    font-weight: 700;
    letter-spacing: -0.66px;
    line-height: 28px;
    color: rgb(25, 25, 27);
    font-size: 18px;
    
}
.message_content_textfield{
    width: 400px;
}
.message_send_button_container{
    margin-top: 32px;
    margin-bottom: 32px;

}
.message_send_button{
    width: 400px;
}
@media screen and (max-width: 700px) {
  section {
    height: auto;
  }
}

.section-text {
  min-width: 300px;
}
.section-text__title {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 40px;
  color: #2b044d;
  max-width: 400px;
}
.section-text__title-centered {
  max-width: 600px;
  text-align: center;
  margin: 0px auto;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 40px;
  color: #fcfcfc;
}
.section-text__title-big {
  font-size: 4rem;
  font-weight: 700;
  color: #161c2d;
  line-height: 1.3;
  margin-bottom: 42px;
  max-width: 600px;
}
.section-text__title-small {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 8px;
  color: #23182c;
}
.section-text__subtitle {
  color: #161c2d;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 34px;
  /* box-shadow: inset 0 -5px 0 #fcf254;  */

}
.section-text__body {
  color: #161c2d;
  font-size: 1rem;
  line-height: 30px;
  margin-bottom: 20px;
  font-weight: 400;
  max-width: 450px;
}

.section-text__desc {
  color: #707b8e;
  font-size: 1rem;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: 400;
  max-width: 300px;
}

.section-image img,
.section-image__small img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.section-image-features img {
  width: 80%;
}

@media screen and (max-width: 700px) {
  .section-image,
  .section-image-features,
  .section-image__small {
    display: none;
  }
}

.feature-box {
  margin-bottom: 32px;
}

.service-cards {
  display: flex;
  margin: 0px auto;
  justify-content: center;
}

.service-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 90px 18px;
  background-color: #fff;
  border-radius: 25px;
  border: none;
  margin: 12px 8px;
  transition: 0.1s all ease-in;
}

.service-card > * {
  margin: 0px auto;
}

.service-card__icon {
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin: 12px auto;
  margin-bottom: 24px;
  border-radius: 50%;
  background-color: #ea4d4e;
  transition: 0.1s all ease-in;
}
.service-card__icon ion-icon {
  color: #fff;
  margin: auto;
  font-size: 3rem;
}

.service-card:hover .service-card__icon,
.service-card.active .service-card__icon {
  background-color: #2b044d;
}

@media screen and (max-width: 700px) {
  .service-cards {
    flex-direction: column;
  }
}

.download-btn {
  background-color: #2b044d;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 16px 40px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s linear;
  margin: 0;
}
.download-btn:hover {
  background-color: #ea4d4e;
}

.section-carousel__images {
  display: flex;
}
.section-carousel__image {
  margin: 0px 6px;
}

@media screen and (max-width: 700px) {
  .section-carousel__images {
    position: relative;
    right: 0;
    left: 0;
  }
}

section#pricing {
  height: auto;
  padding: 0px;
}

.pricing-upper {
  height: calc(50vh - 80px);
  padding: 40px 24px;
  background-color: #ea4d4e;
}
.pricing-lower {
  height: 70vh;
  margin-top: -20vh;
  padding: 0px 24px;
}

.pricing-cards {
  display: flex;
  justify-content: center;
}
.pricing-card {
  width: calc(30vw - 12px);
  margin: 0px 6px;
  max-width: 300px;
  padding: 52px 24px;
  background: #fff;
  border-radius: 25px;
  text-align: center;
  position: relative;
  z-index: 20;
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover {
  box-shadow: 0 10px 30px 0 rgba(133, 66, 189, 0.1);
}
/* .pricing-card::before {
  content: "";
  width: 70px;
  height: 130px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  background-image: url("./images/dot-bg.webp");
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover::before {
  right: -25px;
  bottom: -27px;
} */
.pricing-card__header {
  border-bottom: 2px solid #f4f4f4;
  margin-bottom: 46px;
}
.pricing-card__title {
  color: #6a56a6;
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 26px;
}
.pricing-card__title > span {
  vertical-align: baseline;
  font-size: 40%;
}
.pricing-card__subtitle {
  color: #57667e;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 19px;
  display: inline-block;
}
.pricing-card__features {
  list-style-type: none;
  margin: 12px 0px;
  padding: 0px;
}
.pricing-card__features > li {
  margin: 8px 0px;
  color: #717081;
  font-weight: 300;
  font-size: 1.1rem;
}

.pricing-card__btn {
  display: block;
  margin-top: 24px;
  position: relative;
  cursor: pointer;
  background-color: #2c134d;
  border-radius: 6px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 28px;
  border: 0px;
  color: #fff;
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover .pricing-card__btn {
  background-color: #ff374b;
}

@media screen and (max-width: 700px) {
  .pricing-lower {
    height: auto;
  }
  .pricing-cards {
    flex-direction: column;
  }
  .pricing-card {
    width: calc(100% - 12px);
    margin: 12px auto;
  }
}

section#download {
  position: relative;
  height: 60vh;
  display: flex;
  background-color: #ff374b;
}
section#download .container {
  margin: auto;
}
section#download .section-text {
  max-width: 500px;
}
section#download .section-image {
  max-width: 40vw;
  height: calc(100% - 0px);
}

.download-btn__img {
  display: inline-block;
  height: 56px;
  width: 170px;
  margin: 0px 8px;
  /* margin-top: 24px; */
  margin-top: 44px;
  transition: 0.2s all ease-in-out;
}
.download-btn__img:hover {
  opacity: 0.9;
}

@media screen and (max-width: 700px) {
  section#download {
    height: auto;
  }
}

.text-white {
  color: #fff;
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
}
.col-50 {
  width: 50%;
  padding: 0px 14px;
}
.col-30 {
  width: 30%;
  padding: 0px 14px;
}

@media screen and (max-width: 768px) {
.contact_title_section_title{

}
  .contact_info_section{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  box-sizing: border-box;
  min-width: 0px;
  border-radius: 10px;
  background-color: rgb(247, 247, 251);
  padding: 80px ;
}
.contact_info{
  margin:24px;
}
.send_message_container{
  justify-content: center;
  align-items: center;
  display:block;
}

.message_content_container{
  width: calc(100% );
}
.message_content_textfield{
  width: calc(100% - 40px );
  margin:auto;

}
.message_send_button{
  width: calc(100% - 40px );
  
}
.message_send_button_container{
  width: calc(100% );
  text-align: center;

}
.contact_title_section_descryption{
  margin:40px
}
}