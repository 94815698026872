@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);
@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);
@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);
@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);
@import url(https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 300;
  src:url(/static/media/NanumGothic-Regular.6c84540d.ttf) format("ttf"),
 
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src:url(/static/media/NanumGothic-ExtraBold.d5747134.ttf) format("ttf"),
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 500;
  src:url(/static/media/NanumGothic-Bold.6499af72.ttf) format("ttf"),
 
}


html,
body {
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-family: 'Nanum Gothic','segoe-ui-black-regular','Spoqa Han Sans', 'Spoqa Han Sans JP', "Nunito", "sans-serif";

}

main {
  z-index: 100;
}
main > *:nth-child(odd) {
  background-color: #ffffff;
}

section {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 400px);
  padding: 100px 24px;
}

@media screen and (max-width: 700px) {
  section {
    height: auto;
  }
}

.section-text {
  min-width: 300px;
}
.section-text__title {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 40px;
  color: #2b044d;
  max-width: 400px;
}
.section-text__title-centered {
  max-width: 600px;
  text-align: center;
  margin: 0px auto;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 40px;
  color: #fcfcfc;
}
.section-text__title-big {
  font-size: 4rem;
  font-weight: 700;
  color: #161c2d;
  line-height: 1.3;
  margin-bottom: 42px;
  max-width: 600px;
}
.section-text__title-small {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 8px;
  color: #23182c;
}
.section-text__subtitle {
  color: #161c2d;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 34px;
  /* box-shadow: inset 0 -5px 0 #fcf254;  */

}
.section-text__body {
  color: #161c2d;
  font-size: 1rem;
  line-height: 30px;
  margin-bottom: 20px;
  font-weight: 400;
  max-width: 450px;
}

.section-text__desc {
  color: #707b8e;
  font-size: 1rem;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: 400;
  max-width: 300px;
}

.section-image img,
.section-image__small img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.section-image-features img {
  width: 80%;
}

@media screen and (max-width: 700px) {
  .section-image,
  .section-image-features,
  .section-image__small {
    display: none;
  }
}

.feature-box {
  margin-bottom: 32px;
}

.service-cards {
  display: flex;
  margin: 0px auto;
  justify-content: center;
}

.service-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 90px 18px;
  background-color: #fff;
  border-radius: 25px;
  border: none;
  margin: 12px 8px;
  transition: 0.1s all ease-in;
}

.service-card > * {
  margin: 0px auto;
}

.service-card__icon {
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin: 12px auto;
  margin-bottom: 24px;
  border-radius: 50%;
  background-color: #ea4d4e;
  transition: 0.1s all ease-in;
}
.service-card__icon ion-icon {
  color: #fff;
  margin: auto;
  font-size: 3rem;
}

.service-card:hover .service-card__icon,
.service-card.active .service-card__icon {
  background-color: #2b044d;
}

@media screen and (max-width: 700px) {
  .service-cards {
    flex-direction: column;
  }
}

.download-btn {
  background-color: #2b044d;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 16px 40px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s linear;
  margin: 0;
}
.download-btn:hover {
  background-color: #ea4d4e;
}

.section-carousel__images {
  display: flex;
}
.section-carousel__image {
  margin: 0px 6px;
}

@media screen and (max-width: 700px) {
  .section-carousel__images {
    position: relative;
    right: 0;
    left: 0;
  }
}

section#pricing {
  height: auto;
  padding: 0px;
}

.pricing-upper {
  height: calc(50vh - 80px);
  padding: 40px 24px;
  background-color: #ea4d4e;
}
.pricing-lower {
  height: 70vh;
  margin-top: -20vh;
  padding: 0px 24px;
}

.pricing-cards {
  display: flex;
  justify-content: center;
}
.pricing-card {
  width: calc(30vw - 12px);
  margin: 0px 6px;
  max-width: 300px;
  padding: 52px 24px;
  background: #fff;
  border-radius: 25px;
  text-align: center;
  position: relative;
  z-index: 20;
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover {
  box-shadow: 0 10px 30px 0 rgba(133, 66, 189, 0.1);
}
/* .pricing-card::before {
  content: "";
  width: 70px;
  height: 130px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  background-image: url("./images/dot-bg.webp");
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover::before {
  right: -25px;
  bottom: -27px;
} */
.pricing-card__header {
  border-bottom: 2px solid #f4f4f4;
  margin-bottom: 46px;
}
.pricing-card__title {
  color: #6a56a6;
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 26px;
}
.pricing-card__title > span {
  vertical-align: baseline;
  font-size: 40%;
}
.pricing-card__subtitle {
  color: #57667e;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 19px;
  display: inline-block;
}
.pricing-card__features {
  list-style-type: none;
  margin: 12px 0px;
  padding: 0px;
}
.pricing-card__features > li {
  margin: 8px 0px;
  color: #717081;
  font-weight: 300;
  font-size: 1.1rem;
}

.pricing-card__btn {
  display: block;
  margin-top: 24px;
  position: relative;
  cursor: pointer;
  background-color: #2c134d;
  border-radius: 6px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 28px;
  border: 0px;
  color: #fff;
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover .pricing-card__btn {
  background-color: #ff374b;
}

@media screen and (max-width: 700px) {
  .pricing-lower {
    height: auto;
  }
  .pricing-cards {
    flex-direction: column;
  }
  .pricing-card {
    width: calc(100% - 12px);
    margin: 12px auto;
  }
}

section#download {
  position: relative;
  height: 60vh;
  display: flex;
  background-color: #ff374b;
}
section#download .container {
  margin: auto;
}
section#download .section-text {
  max-width: 500px;
}
section#download .section-image {
  max-width: 40vw;
  height: calc(100% - 0px);
}

.download-btn__img {
  display: inline-block;
  height: 56px;
  width: 170px;
  margin: 0px 8px;
  /* margin-top: 24px; */
  margin-top: 44px;
  transition: 0.2s all ease-in-out;
}
.download-btn__img:hover {
  opacity: 0.9;
}

@media screen and (max-width: 700px) {
  section#download {
    height: auto;
  }
}

.text-white {
  color: #fff;
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
}
.col-50 {
  width: 50%;
  padding: 0px 14px;
}
.col-30 {
  width: 30%;
  padding: 0px 14px;
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 300;
  src:url(/static/media/NanumGothic-Regular.6c84540d.ttf) format("ttf"),
 
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src:url(/static/media/NanumGothic-ExtraBold.d5747134.ttf) format("ttf"),
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 500;
  src:url(/static/media/NanumGothic-Bold.6499af72.ttf) format("ttf"),
 
}


html,
body {
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-family: 'Nanum Gothic','segoe-ui-black-regular','Spoqa Han Sans', 'Spoqa Han Sans JP', "Nunito", "sans-serif";

}
.nav {
  width: calc(100% - 50px);
  position: absolute;
  padding: 0px 25px;
  top: 0px;
  left: 0px;
  display: flex;
  height: 70px;
  overflow: hidden;
  z-index: 1000;
  background-color: #fff;
  transition:all 0.4s ease 0s;

}
.nav-stickey {
  -webkit-animation: nav-enter-anim 0.15s ease-in;
          animation: nav-enter-anim 0.15s ease-in;
  background-color: #fff;
  position: fixed;
  box-shadow: 0 8px 6px -6px #fcfcfc;
}

.nav-content {
  height: 100%;
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

.nav-logo {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  font-size: 1.5rem;
  color: #23182c;
  letter-spacing:-0.5px;
}

.nav-links__container {
  display: flex;
  margin-left: auto;
}
.nav-link {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  margin: 0px 8px;
}
.nav .nav-link__text {
  margin: auto 0px;
  padding: 0px 12px;
  color: #161c2d;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.4s ease 0s;
}

.nav-link__background {
  margin-top: -2px;
  height: 2px;
  width: 0%;
  background: #fff27f;
  transition: all 0.4s ease 0s;
}
.nav-link:hover .nav-link__background {
  width: 100%;
}

.nav-menu__icon {
  display: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin: auto 0px;
  border-radius: 5px;
  transition: 0.1s all ease-in;
}
.nav-menu__icon div {
  height: 2px;
  width: 100%;
  background-color: #23182c;
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: 0.1s all ease-in;
  margin: 0px auto;
}

.nav-open .nav-menu__icon div:nth-of-type(1) {
  width: 40px;
  margin-left: 0px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.nav-open .nav-menu__icon div:nth-of-type(2) {
  width: 40px;
  margin-right: 0px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

@media screen and (max-width: 700px) {
  .nav-open {
    height: 100vh;
    position: fixed;
    background-color: #fcfcfc;
    -webkit-animation: nav-open 0.2s ease-in-out;
            animation: nav-open 0.2s ease-in-out;
  }
  .nav-open .nav-content {
    height: 70px;
    margin-top: 0px;
  }
  .nav-links__container {
    position: absolute;
    top: 70px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .nav-link {
    height: 60px;
  }
  .nav-link > * {
    margin-left: auto;
    margin-right: auto;
  }
  .nav-menu__icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .nav-open .nav-menu__icon {
    justify-content: space-between;
  }
}

@keyframes nav-enter-anim {
  from {
    top: -70px;
  }
  to {
    top: 0px;
  }
}
@-webkit-keyframes nav-enter-anim {
  from {
    top: -70px;
  }
  to {
    top: 0px;
  }
}

@keyframes nav-open {
  from {
    height: 70px;
  }
  to {
    height: 100vh;
  }
}
@-webkit-keyframes nav-open {
  from {
    height: 70px;
  }
  to {
    height: 100vh;
  }
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 300;
  src:url(/static/media/NanumGothic-Regular.6c84540d.ttf) format("ttf"),
 
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src:url(/static/media/NanumGothic-ExtraBold.d5747134.ttf) format("ttf"),
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 500;
  src:url(/static/media/NanumGothic-Bold.6499af72.ttf) format("ttf"),
 
}


html,
body {
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-family: 'Nanum Gothic','segoe-ui-black-regular','Spoqa Han Sans', 'Spoqa Han Sans JP', "Nunito", "sans-serif";

}

footer {
  height: 70vh;
  padding: 0px 24px;
  display: flex;
  font-family: 'Nanum Gothic','segoe-ui-black-regular','Spoqa Han Sans', 'Spoqa Han Sans JP', "Nunito", "sans-serif";

}

footer > .container {
  margin: auto;
  display: flex;
  flex-direction: column;
}
footer .row {
  margin: 12px -12px;
}
footer .row .col-30 {
  margin: 0px 12px;
}

.footer-text__title {
  color: #000;
  font-size: 18px;
  margin-bottom: 48px;
  font-weight: 700;
}
.footer-text__body {
  color: #707b8e;
  font-size: 1.1rem;
  line-height: 1.8;
  margin: 8px 0px;
}

.footer-list {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.footer-list li {
  margin: 8px 0px;
  transition: 0.1s all ease-in-out;
}
.footer-list li:hover {
  margin-left: 6px;
}
.footer-list li a {
  display: block;
  text-decoration: none;
  color: #868c98;
  font-weight: 300;
  font-size: 1.1rem;
}

.footer-input {
  display: flex;
}
.footer-input input {
  background-color: #888;
  background: #f9f9fe;
  padding: 10px 14px;
  border: 0px;
  width: 160px;
}
.footer-input__icon {
  color: #fff;
  font-size: 1.5rem;
  background: #ea4d4e;
  display: flex;
  padding: 10px 14px;
}

.copyright {
  color: #888;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
  margin-top: 24px;
  margin: 0px auto;
}

@media screen and (max-width: 700px) {
  footer {
    height: auto;
  }
  footer .row {
    flex-direction: column;
  }
  footer .row .col-30 {
    width: calc(100% - 24px);
    margin: 12px auto;
  }
  .footer-text__title {
    margin-bottom: 12px;
  }
}

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 300;
  src:url(/static/media/NanumGothic-Regular.6c84540d.ttf) format("ttf"),
 
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src:url(/static/media/NanumGothic-ExtraBold.d5747134.ttf) format("ttf"),
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 500;
  src:url(/static/media/NanumGothic-Bold.6499af72.ttf) format("ttf"),
 
}


html,
body {
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-family: 'Nanum Gothic','segoe-ui-black-regular','Spoqa Han Sans', 'Spoqa Han Sans JP', "Nunito", "sans-serif";

}

main {
  z-index: 100;
}
main > *:nth-child(odd) {
  background-color: #ffffff;
}



section {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 400px);
  padding: 100px 24px;
}

@media screen and (max-width: 765px) {
  section {
    height: auto;
  }
}
/* main start */
.main_container{
margin:0 80px;
  margin-top: 60px;

}
.main_side{
  margin:auto;
  display: flex;
  flex-direction: row;
  height:100%;
  align-items: center;
  justify-content: space-around;
  min-height: 1px;

}
.main_image_container{

width:40%;
}
.main_image{
  max-height:100%; max-width:100%;



}
.main_text_container{
  display: flex;
  flex-direction: column;
  height:100%;
  align-items: center;
  justify-content: space-evenly;
}
.main_text_title{
  font-size: 54px;
  padding-left: 24px;
  font-weight: 700;
}
.main_text_des{
  font-size: 16px;
  padding-left: 24px;
  line-height: 30px;
  font-size:16px;
  letter-spacing:-.5px;
   line-height: 1.75;
    font-weight:300;
     color:#666666;

}
.main_button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}



@media screen and (max-width: 765px) {
  .main_container_above{
    height: 100px;
  }
  .main_container{

    }
    .main_side{
      
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      
    
    }
    .main_image_container{
    width:100%;
    }
    .main_image{
      max-height:100%; max-width:100%;
    
    
    }
    .main_text_container{
      width:100%;
      display: flex;
      flex-direction: column;
      height:100%;
      align-items: center;
      justify-content: space-evenly;
    }
    .main_text_title{
      font-size: 34px;
      padding-left: 24px;
      font-weight: 700;
      line-height: 50px;

      
    }
    .main_text_des{
      font-size: 14px;
      padding-left: 24px;
      line-height: 30px;
    
    }
    .main_button{
      width:100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    
    }

}
/* main end */
/* vidoe start */

.vidoe_container{
  margin:auto;
  margin: 0 80px;

  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vidoe{
   padding: 24px;
    min-width: 90%;
    aspect-ratio: 1.77777/1 ;

}
.vidoe_des{
  margin-top: 20px;
   font-size: 16px;
    letter-spacing: -.5px;
     line-height: 1.75;
      font-weight: 300;
      font-size:16px;
  letter-spacing:-.5px;
   line-height: 1.75;
    font-weight:300;
     color:#666666;
  margin: "0 32px" ;
}
@media screen and (max-width: 765px) {
  .vidoe_container{
    margin-top: 50px;
    /* width: calc(100% - 80px); */
     height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .vidoe{
     padding: 24px;
      min-width: 100%;
       aspect-ratio: 1.77777/1 ;
  
  }
  .vidoe_des{
    margin-top: 50px;
     font-size: 16px;
      letter-spacing: -.5px;
       line-height: 1.75;
        font-weight: 300;
    margin: "0 32px" ;
    font-size:16px;
  letter-spacing:-.5px;
   line-height: 1.75;
    font-weight:300;
     color:#666666;
  }
}
/* video ends */
/* businessModel start */
.business_container{
margin: auto;
/* margin:80px; */
margin-top: 70px;

display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;

}
.business_image_container_margin{
  margin:0 80px;
  
}
.business_image_container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  
}
.business_image1_container{
 margin:auto;
}

.business_image1{
  max-height:100%; max-width:100%;


}
.business_image2_container{
  margin-top: 90px;
  width: 80%;


}
.business_image2{
  max-height:100%; max-width:100%;


}
.business_text_container{
margin-top: 50px;
  text-align: center;
}
.business_text1{
  font-size:16px;
  letter-spacing:-.5px;
   line-height: 1.75;
    font-weight:300;
     color:#666666;
}
.business_text2{
  font-size:16px;
  letter-spacing:-.5px;
   line-height: 1.75;
    font-weight:300;
     color:#666666;
}
@media screen and (max-width: 765px) {
  .business_image2_container{
    margin-top: 90px;
    width: 100%;
  
  
  }

}
/* businessModel end .*/


/* zerowaste start */
.zerowaste_container{
 display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin:auto;
  margin-top: 120px;

}

.zerowaste_container_margin{
  width:calc(100% - 160px);
  margin:80px;
  
}
.zerowaste_text_container{
  width: 100%;
  display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   margin:auto;
}
.zerowaste_title{
  margin-top: 24px;
  font-size: 32px;
  letter-spacing: "-.8px";
  line-height: 1.29;
  font-weight: 700;
  margin-bottom: 32px;
  width: 80%;
text-align: center;
}
.zerowaste_des{
  width: 60%; 
  margin-top:24px;
   font-size:16px;
    letter-spacing:-.5px;
     line-height: 1.75;
      font-weight:300;
       color:#666666;
   margin: 0 32px;
}
.zerowaste_text_image_card{
  margin-top: 60px;
   border-radius: 20px;
    padding: 20px;
     
      width: 70%;
       display: flex;
        flex-direction: column;
         justify-content: center;
   align-items: center;
   
}
.zerowaste_text_image{
  width: 70%;
  
  margin-top: 40px;

  
  
}
.zerowaste_image_container{
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin:auto;
  margin:0 80px;
  margin-top: 50px;
}

.zerowaste_image_container1{
  width: 45%;
}
.zerowaste_image1{
  max-width: 100%;
  max-width: 100%;

}
.zerowaste_image_container2{
  width: 45%;

}
.zerowaste_image2{
  max-width: 100%;
  max-width: 100%;
}
@media screen and (max-width: 765px) {
  .zerowaste_image_container{
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin:auto;
    margin:0 80px;
    margin-top: 50px;
  }

.zerowaste_image_container1{
  width: 100%;
}

.zerowaste_image_container2{
  width: 100%;
  margin-top: 30px;
}
}
/* zerowaste end */

/* pics start */
.pics_container{
  margin:0 80px;
  margin-top: 70px;

}
.pics_container_container{
margin-top: 150px;
  width:100%;
display: flex;
flex-direction: row;
justify-content: space-between;
}
.pics_container_container > div{
  margin:15px;
  
}
.pics_pic1_container{
flex:1 1;
      text-align: center;

}
.pics_pic1{
max-width: 100%;
max-height: 100%;
}
.pics_pic_text{
  margin-top:20px;
  font-size:22px;
  font-weight: 500;
  letter-spacing:-.5px;
    line-height: 1.75;
      
}
.pics_pic2_container{
  flex: 1 1;
  text-align: center;

}
.pics_pic2{
  max-width: 100%;
  max-height: 100%;

}

.pics_pic3_container{
flex: 1.86825 1;
text-align: start;


}
.pics_pic3{
  max-width: 100%;
  max-height: 100%;


}
.pics_pic_text3{

  margin-top:12px;
  font-size:22px;
  font-weight: 500;
  letter-spacing:-.5px;
    line-height: 1.75;
}
.pics_pic_text4{
  margin-top:12px;
  font-size:22px;
  font-weight: 500;
  letter-spacing:-.5px;
    line-height: 1.75;
}
.pics_text_container{
  text-align: end;
  font-size:16px;
   letter-spacing:-.5px;
    line-height: 1.75;
     font-weight:300;
      color:#666666;

}
.pics_pic3_lastText{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.pics_text_bold{
 font-weight: 700;
}
.pics_text_last{
}
@media screen and (max-width: 765px) {
  .pics_container_container{
      width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }
    .pics_pic3_container{
      flex: 1.86825 1;
      text-align: center;
  
      
      }
      .pics_pic_text4{
        margin-top: 20px;
      }
      .pics_pic3_lastText{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .pics_pic_text5{
        display: block;
      }
}
/* pics end */


/* lastpic start */

.last_pic_container{
  
  margin:80px;
  margin-top: 120px;
  /* margin:auto; */
  
}
.last_pic_container_container{
  width: 100%;

}
.last_pic{
  max-height:100%; max-width:100%;

}
@media screen and (max-width: 765px) {

}
/* lastpic end */


/* footer start */
.footer_container{
  border-top: 1px solid #ebebeb;
}
.footer_container_container{
  
  width: 100% ;
  height: 250px;
   background-color: #fff;
    display: flex;
     flex-direction: row;
      justify-content: space-around;
       align-items: start;
}
.footer_company_container{
margin-top: 24px;
 background-color: #fff;
 display: flex;
 flex-direction: column;
 justify-content: start;
 align-items: center;

}
.footer_company_logo{
  margin-top: 4px;
  height: 18px;
}
.footer_company_name{
 margin-top: 12px; font-size: 12px; color: #888;
}
.footer_company_info_container{
  margin-top: 24px;
   height: 40%;
   background-color: #fff;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: start;

}
.footer_company_info_text{
  margin-bottom: 8px;
   font-size: 12px;
   color: #888; 
}
.footer_company_info_daepyo_text{
  font-size: 12px;
   color: #888;
}
.footer_company_info_address_text{
  font-size: 12px;
   color: #888;
}
.footer_company_info_number_text{
  font-size: 12px;
   color: #888;
}
.footer_company_contact_container{
  margin-top: 24px;
   background-color: #fff;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   align-items: start;

}
.footer_company_contact_text{
  margin-bottom: 8px;
   font-size: 12px;
   color: #888; 

}
.footer_company_tel_text{
  font-size: 12px;
   color: #888;

}
.footer_company_fax_text{
  font-size: 12px;
   color: #888;

}
.footer_company_email_text{
  font-size: 12px;
   color: #888;

}
.footer_company_kakao_text{
  font-size: 12px;
   color: #888;

}
.footer_company_sns_container{
  margin-top: 24px;
   
   background-color: #fff;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}
.footer_company_sns_apple{
  margin-right: 12px;

  margin-top: -3px;
}
.footer_company_sns_google{
  margin-right: 12px;
   height: 18px;
}
.footer_company_sns_youtube{
  margin-right: 12px
}
@media screen and (max-width: 765px) {
  .footer_container{
    border-top: 1px solid #ebebeb;

  }
  .footer_container_container > div{
    margin-left: 20px;
  }
  .footer_container_container{

    margin-top: 30px;
    height: 250px;
     background-color: #fff;
      display: flex;
       flex-direction: column-reverse;
        justify-content: space-around;
         align-items: start;
  }
  .footer_company_sns_container{
    margin-left: -32px;
  }

}
/* footer end */

.section-text {
  min-width: 300px;
}
.section-text__title {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 40px;
  color: #2b044d;
  max-width: 400px;
}
.section-text__title-centered {
  max-width: 600px;
  text-align: center;
  margin: 0px auto;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 40px;
  color: #fcfcfc;
}
.section-text__title-big {
  font-size: 4rem;
  font-weight: 700;
  color: #161c2d;
  line-height: 1.3;
  margin-bottom: 42px;
  max-width: 600px;
}
.section-text__title-small {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 8px;
  color: #23182c;
}
.section-text__subtitle {
  color: #161c2d;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 34px;
  /* box-shadow: inset 0 -5px 0 #fcf254;  */

}
.section-text__body {
  color: #161c2d;
  font-size: 1rem;
  line-height: 30px;
  margin-bottom: 20px;
  font-weight: 400;
  max-width: 450px;
}

.section-text__desc {
  color: #707b8e;
  font-size: 1rem;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: 400;
  max-width: 300px;
}

.section-image img,

.section-image__small img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.section-image-features img {
  width: 80%;
}

@media screen and (max-width: 700px) {
  .section-image,
  .section-image-features,
  .section-image__small {
    display: none;
  }
}

.feature-box {
  margin-bottom: 32px;
}

.service-cards {
  display: flex;
  margin: 0px auto;
  justify-content: center;
}

.service-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 90px 18px;
  background-color: #fff;
  border-radius: 25px;
  border: none;
  margin: 12px 8px;
  transition: 0.1s all ease-in;
}

.service-card > * {
  margin: 0px auto;
}

.service-card__icon {
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin: 12px auto;
  margin-bottom: 24px;
  border-radius: 50%;
  background-color: #ea4d4e;
  transition: 0.1s all ease-in;
}
.service-card__icon ion-icon {
  color: #fff;
  margin: auto;
  font-size: 3rem;
}

.service-card:hover .service-card__icon,
.service-card.active .service-card__icon {
  background-color: #2b044d;
}

@media screen and (max-width: 700px) {
  .service-cards {
    flex-direction: column;
  }
}

.download-btn {
  background-color: #2b044d;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 16px 40px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s linear;
  margin: 0;
}
.download-btn:hover {
  background-color: #ea4d4e;
}

.section-carousel__images {
  display: flex;
}
.section-carousel__image {
  margin: 0px 6px;
}

@media screen and (max-width: 700px) {
  .section-carousel__images {
    position: relative;
    right: 0;
    left: 0;
  }
}

section#pricing {
  height: auto;
  padding: 0px;
}

.pricing-upper {
  height: calc(50vh - 80px);
  padding: 40px 24px;
  background-color: #ea4d4e;
}
.pricing-lower {
  height: 70vh;
  margin-top: -20vh;
  padding: 0px 24px;
}

.pricing-cards {
  display: flex;
  justify-content: center;
}
.pricing-card {
  width: calc(30vw - 12px);
  margin: 0px 6px;
  max-width: 300px;
  padding: 52px 24px;
  background: #fff;
  border-radius: 25px;
  text-align: center;
  position: relative;
  z-index: 20;
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover {
  box-shadow: 0 10px 30px 0 rgba(133, 66, 189, 0.1);
}
/* .pricing-card::before {
  content: "";
  width: 70px;
  height: 130px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  background-image: url("./images/dot-bg.webp");
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover::before {
  right: -25px;
  bottom: -27px;
} */
.pricing-card__header {
  border-bottom: 2px solid #f4f4f4;
  margin-bottom: 46px;
}
.pricing-card__title {
  color: #6a56a6;
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 26px;
}
.pricing-card__title > span {
  vertical-align: baseline;
  font-size: 40%;
}
.pricing-card__subtitle {
  color: #57667e;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 19px;
  display: inline-block;
}
.pricing-card__features {
  list-style-type: none;
  margin: 12px 0px;
  padding: 0px;
}
.pricing-card__features > li {
  margin: 8px 0px;
  color: #717081;
  font-weight: 300;
  font-size: 1.1rem;
}

.pricing-card__btn {
  display: block;
  margin-top: 24px;
  position: relative;
  cursor: pointer;
  background-color: #2c134d;
  border-radius: 6px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 28px;
  border: 0px;
  color: #fff;
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover .pricing-card__btn {
  background-color: #ff374b;
}

@media screen and (max-width: 700px) {
  .pricing-lower {
    height: auto;
  }
  .pricing-cards {
    flex-direction: column;
  }
  .pricing-card {
    width: calc(100% - 12px);
    margin: 12px auto;
  }
}

section#download {
  position: relative;
  height: 60vh;
  display: flex;
  background-color: #ff374b;
}
section#download .container {
  margin: auto;
}
section#download .section-text {
  max-width: 500px;
}
section#download .section-image {
  max-width: 40vw;
  height: calc(100% - 0px);
}

.download-btn__img {
  display: inline-block;
  height: 56px;
  width: 170px;
  margin: 0px 8px;
  /* margin-top: 24px; */
  margin-top: 44px;
  transition: 0.2s all ease-in-out;
}
.download-btn__img:hover {
  opacity: 0.9;
}

@media screen and (max-width: 700px) {
  section#download {
    height: auto;
  }
}

.text-white {
  color: #fff;
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
}
.col-50 {
  width: 50%;
  padding: 0px 14px;
}
.col-30 {
  width: 30%;
  padding: 0px 14px;
}
a { color: inherit; } 

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 300;
  src:url(/static/media/NanumGothic-Regular.6c84540d.ttf) format("ttf"),
 
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src:url(/static/media/NanumGothic-ExtraBold.d5747134.ttf) format("ttf"),
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 500;
  src:url(/static/media/NanumGothic-Bold.6499af72.ttf) format("ttf"),
 
}


html,
body {
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-family: 'Nanum Gothic','segoe-ui-black-regular','Spoqa Han Sans', 'Spoqa Han Sans JP', "Nunito", "sans-serif";

}

main {
  z-index: 100;
  width:100%;
}
main > *:nth-child(odd) {
  background-color: #ffffff;
}

.contact_title_section {
 width:100%;
 height:400px;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.contact_title_section_title{
    margin-top: 200px;
    font-size: 80px;
    margin-bottom: 24px;
    font-weight: 700;
}
.contact_title_section_descryption{

    margin-bottom: 0px;
    font-size: 21px;
    font-weight: 400;
    letter-spacing: -0.66px;
    line-height: 38px;
    color: rgba(25, 25, 27, 0.75);
}
.contact_info_section{
    
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-left: 150px;
    margin-right: 150px;
    margin-top: 40px;
    box-sizing: border-box;
    min-width: 0px;
    border-radius: 10px;
    background-color: rgb(247, 247, 251);
    padding: 80px ;
}
.contact_info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact_title{
    font-weight: 700;
    letter-spacing: -0.66px;
    line-height: 28px;
    color: rgb(25, 25, 27);
    font-size: 24px;
}
.contact_text{
    margin-bottom: 0px;
    font-size: 21px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 38px;
    color: rgba(25, 25, 27, 0.75);
    margin-top: 12px;
    
}
.or_message_container{

    padding-top: 3rem!important;
    margin-top: 1.5rem!important;
    
    
    display: flex;
    align-items: center;
    justify-content: center;

}
.or_message_text{

    font-weight: 700;
    letter-spacing: -0.66px;
    line-height: 28px;
    color: rgb(25, 25, 27);
    font-size: 24px;
    text-align: center;
}
.send_message_container{
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 12px;
    

}
.message_content_container{

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin:16px;
}
.message_content_title{
    font-weight: 700;
    letter-spacing: -0.66px;
    line-height: 28px;
    color: rgb(25, 25, 27);
    font-size: 18px;
    
}
.message_content_textfield{
    width: 400px;
}
.message_send_button_container{
    margin-top: 32px;
    margin-bottom: 32px;

}
.message_send_button{
    width: 400px;
}
@media screen and (max-width: 700px) {
  section {
    height: auto;
  }
}

.section-text {
  min-width: 300px;
}
.section-text__title {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 40px;
  color: #2b044d;
  max-width: 400px;
}
.section-text__title-centered {
  max-width: 600px;
  text-align: center;
  margin: 0px auto;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 40px;
  color: #fcfcfc;
}
.section-text__title-big {
  font-size: 4rem;
  font-weight: 700;
  color: #161c2d;
  line-height: 1.3;
  margin-bottom: 42px;
  max-width: 600px;
}
.section-text__title-small {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 8px;
  color: #23182c;
}
.section-text__subtitle {
  color: #161c2d;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 34px;
  /* box-shadow: inset 0 -5px 0 #fcf254;  */

}
.section-text__body {
  color: #161c2d;
  font-size: 1rem;
  line-height: 30px;
  margin-bottom: 20px;
  font-weight: 400;
  max-width: 450px;
}

.section-text__desc {
  color: #707b8e;
  font-size: 1rem;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: 400;
  max-width: 300px;
}

.section-image img,
.section-image__small img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.section-image-features img {
  width: 80%;
}

@media screen and (max-width: 700px) {
  .section-image,
  .section-image-features,
  .section-image__small {
    display: none;
  }
}

.feature-box {
  margin-bottom: 32px;
}

.service-cards {
  display: flex;
  margin: 0px auto;
  justify-content: center;
}

.service-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 90px 18px;
  background-color: #fff;
  border-radius: 25px;
  border: none;
  margin: 12px 8px;
  transition: 0.1s all ease-in;
}

.service-card > * {
  margin: 0px auto;
}

.service-card__icon {
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin: 12px auto;
  margin-bottom: 24px;
  border-radius: 50%;
  background-color: #ea4d4e;
  transition: 0.1s all ease-in;
}
.service-card__icon ion-icon {
  color: #fff;
  margin: auto;
  font-size: 3rem;
}

.service-card:hover .service-card__icon,
.service-card.active .service-card__icon {
  background-color: #2b044d;
}

@media screen and (max-width: 700px) {
  .service-cards {
    flex-direction: column;
  }
}

.download-btn {
  background-color: #2b044d;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 16px 40px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s linear;
  margin: 0;
}
.download-btn:hover {
  background-color: #ea4d4e;
}

.section-carousel__images {
  display: flex;
}
.section-carousel__image {
  margin: 0px 6px;
}

@media screen and (max-width: 700px) {
  .section-carousel__images {
    position: relative;
    right: 0;
    left: 0;
  }
}

section#pricing {
  height: auto;
  padding: 0px;
}

.pricing-upper {
  height: calc(50vh - 80px);
  padding: 40px 24px;
  background-color: #ea4d4e;
}
.pricing-lower {
  height: 70vh;
  margin-top: -20vh;
  padding: 0px 24px;
}

.pricing-cards {
  display: flex;
  justify-content: center;
}
.pricing-card {
  width: calc(30vw - 12px);
  margin: 0px 6px;
  max-width: 300px;
  padding: 52px 24px;
  background: #fff;
  border-radius: 25px;
  text-align: center;
  position: relative;
  z-index: 20;
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover {
  box-shadow: 0 10px 30px 0 rgba(133, 66, 189, 0.1);
}
/* .pricing-card::before {
  content: "";
  width: 70px;
  height: 130px;
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  background-image: url("./images/dot-bg.webp");
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover::before {
  right: -25px;
  bottom: -27px;
} */
.pricing-card__header {
  border-bottom: 2px solid #f4f4f4;
  margin-bottom: 46px;
}
.pricing-card__title {
  color: #6a56a6;
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 26px;
}
.pricing-card__title > span {
  vertical-align: baseline;
  font-size: 40%;
}
.pricing-card__subtitle {
  color: #57667e;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 19px;
  display: inline-block;
}
.pricing-card__features {
  list-style-type: none;
  margin: 12px 0px;
  padding: 0px;
}
.pricing-card__features > li {
  margin: 8px 0px;
  color: #717081;
  font-weight: 300;
  font-size: 1.1rem;
}

.pricing-card__btn {
  display: block;
  margin-top: 24px;
  position: relative;
  cursor: pointer;
  background-color: #2c134d;
  border-radius: 6px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 16px 28px;
  border: 0px;
  color: #fff;
  transition: 0.2s all ease-in-out;
}
.pricing-card:hover .pricing-card__btn {
  background-color: #ff374b;
}

@media screen and (max-width: 700px) {
  .pricing-lower {
    height: auto;
  }
  .pricing-cards {
    flex-direction: column;
  }
  .pricing-card {
    width: calc(100% - 12px);
    margin: 12px auto;
  }
}

section#download {
  position: relative;
  height: 60vh;
  display: flex;
  background-color: #ff374b;
}
section#download .container {
  margin: auto;
}
section#download .section-text {
  max-width: 500px;
}
section#download .section-image {
  max-width: 40vw;
  height: calc(100% - 0px);
}

.download-btn__img {
  display: inline-block;
  height: 56px;
  width: 170px;
  margin: 0px 8px;
  /* margin-top: 24px; */
  margin-top: 44px;
  transition: 0.2s all ease-in-out;
}
.download-btn__img:hover {
  opacity: 0.9;
}

@media screen and (max-width: 700px) {
  section#download {
    height: auto;
  }
}

.text-white {
  color: #fff;
}

.row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 0;
}
.col-50 {
  width: 50%;
  padding: 0px 14px;
}
.col-30 {
  width: 30%;
  padding: 0px 14px;
}

@media screen and (max-width: 768px) {
.contact_title_section_title{

}
  .contact_info_section{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 40px;
  box-sizing: border-box;
  min-width: 0px;
  border-radius: 10px;
  background-color: rgb(247, 247, 251);
  padding: 80px ;
}
.contact_info{
  margin:24px;
}
.send_message_container{
  justify-content: center;
  align-items: center;
  display:block;
}

.message_content_container{
  width: calc(100% );
}
.message_content_textfield{
  width: calc(100% - 40px );
  margin:auto;

}
.message_send_button{
  width: calc(100% - 40px );
  
}
.message_send_button_container{
  width: calc(100% );
  text-align: center;

}
.contact_title_section_descryption{
  margin:40px
}
}
