@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap");
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);

@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 300;
  src:url("../fonts/NanumGothic-Regular.ttf") format("ttf"),
 
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  src:url("../fonts/NanumGothic-ExtraBold.ttf") format("ttf"),
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 500;
  src:url("../fonts/NanumGothic-Bold.ttf") format("ttf"),
 
}


html,
body {
  width: 100%;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-family: 'Nanum Gothic','segoe-ui-black-regular','Spoqa Han Sans', 'Spoqa Han Sans JP', "Nunito", "sans-serif";

}
.nav {
  width: calc(100% - 50px);
  position: absolute;
  padding: 0px 25px;
  top: 0px;
  left: 0px;
  display: flex;
  height: 70px;
  overflow: hidden;
  z-index: 1000;
  background-color: #fff;
  transition:all 0.4s ease 0s;

}
.nav-stickey {
  animation: nav-enter-anim 0.15s ease-in;
  background-color: #fff;
  position: fixed;
  -webkit-box-shadow: 0 8px 6px -6px #fcfcfc;
  -moz-box-shadow: 0 8px 6px -6px #fcfcfc;
  box-shadow: 0 8px 6px -6px #fcfcfc;
}

.nav-content {
  height: 100%;
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

.nav-logo {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 700;
  font-size: 1.5rem;
  color: #23182c;
  letter-spacing:-0.5px;
}

.nav-links__container {
  display: flex;
  margin-left: auto;
}
.nav-link {
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  margin: 0px 8px;
}
.nav .nav-link__text {
  margin: auto 0px;
  padding: 0px 12px;
  color: #161c2d;
  font-size: 15px;
  font-weight: 700;
  transition: all 0.4s ease 0s;
}

.nav-link__background {
  margin-top: -2px;
  height: 2px;
  width: 0%;
  background: #fff27f;
  transition: all 0.4s ease 0s;
}
.nav-link:hover .nav-link__background {
  width: 100%;
}

.nav-menu__icon {
  display: none;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin: auto 0px;
  border-radius: 5px;
  transition: 0.1s all ease-in;
}
.nav-menu__icon div {
  height: 2px;
  width: 100%;
  background-color: #23182c;
  transform-origin: left;
  transition: 0.1s all ease-in;
  margin: 0px auto;
}

.nav-open .nav-menu__icon div:nth-of-type(1) {
  width: 40px;
  margin-left: 0px;
  transform: rotate(45deg);
}
.nav-open .nav-menu__icon div:nth-of-type(2) {
  width: 40px;
  margin-right: 0px;
  transform: rotate(-45deg);
}

@media screen and (max-width: 700px) {
  .nav-open {
    height: 100vh;
    position: fixed;
    background-color: #fcfcfc;
    animation: nav-open 0.2s ease-in-out;
  }
  .nav-open .nav-content {
    height: 70px;
    margin-top: 0px;
  }
  .nav-links__container {
    position: absolute;
    top: 70px;
    left: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .nav-link {
    height: 60px;
  }
  .nav-link > * {
    margin-left: auto;
    margin-right: auto;
  }
  .nav-menu__icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .nav-open .nav-menu__icon {
    justify-content: space-between;
  }
}

@keyframes nav-enter-anim {
  from {
    top: -70px;
  }
  to {
    top: 0px;
  }
}
@-webkit-keyframes nav-enter-anim {
  from {
    top: -70px;
  }
  to {
    top: 0px;
  }
}

@keyframes nav-open {
  from {
    height: 70px;
  }
  to {
    height: 100vh;
  }
}
@-webkit-keyframes nav-open {
  from {
    height: 70px;
  }
  to {
    height: 100vh;
  }
}
